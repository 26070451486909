import React from "react";
import Solutions from "../../Components/Solutions/Solutions";

const Solution = () => {
  return (
    <>
      <Solutions />
    </>
  );
};

export default Solution;
