import React from "react";
import Products from "../../Components/Products/Products";
const Product = () => {
  return (
    <>
      <Products />
    </>
  );
};

export default Product;
