import React from "react";
import Service from "../../Components/Service/Service";

const Services = () => {
  return (
    <>
      <Service />
    </>
  );
};

export default Services;
