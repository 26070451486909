import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import "./blog.css";
const Single = () => {
  return (
    <>
      <Breadcrumb />
    </>
  );
};

export default Single;
